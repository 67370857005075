<template>
  <div>
    <b-table
      id="table"
      :per-page="perPage"
      :bordered="true"
      :current-page="currentPage"
      :items="items_sorted"
      :fields="fields"
      striped
      responsive
      hover
    >
      <template #cell(form_type)="row">
        <span class="font-weight-bold text-dark w-50 text-center">
          {{ toTitleCase(row.item.form_type) }}</span
        >
      </template>
      <template #cell(qc_type)="row">
        <span class="font-weight-bold text-dark w-50 text-center">
          {{ toTitleCase(row.item.qc_type.toString()) }}</span
        >
      </template>
      <template #cell(parameter)="row">
        <span class="font-weight-bold text-dark w-50 text-center">
          {{ toTitleCase(row.item.parameter.toString()) }}</span
        >
      </template>
      <!-- <template #cell(action)="row">
        <b-button variant="primary" size="sm" @click="confirmationApprovement(row.item)"> Approve </b-button>
      </template> -->
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <!-- <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="table"></b-pagination>
    <b-table id="table" :per-page="perPage" :current-page="currentPage" :items="items_sorted" :fields="fields" striped responsive>
      <template #cell(details)="row">
        <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails"></b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row>
            <b-col>
              <p>Before</p>
              <ul v-if="row.item.histories.before == null">
                <li>Tidak ada data</li>
              </ul>
              <ul v-else v-for="history in row.item.histories.before" :key="history.atribut">
                <li v-for="(value, key) in history" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-col>
            <b-col>
              <p>After</p>
              <ul v-if="row.item.histories.after == null">
                <li>Tidak ada data</li>
              </ul>
              <ul v-else v-for="history in row.item.histories.after" :key="history.atribut">
                <li v-for="(value, key) in history" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>
    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="table"></b-pagination> -->
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BFormCheckbox,
  BPagination,
} from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import metadata from "@/api/metadata";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BPagination,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      // fields: ["station_id", "station_name", "form_type", "data_timestamp", "observation_time", "modify_by", "details"],
      fields: [
        {
          label: "STASIUN",
          key: "station_name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "DATA TIMESTAMP",
          key: "data_timestamp",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "FORM",
          key: "form_type",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "OBSERVATION TIME",
          key: "observation_time",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "QC TYPE",
          key: "qc_type",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "PARAMATER",
          key: "parameter",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "ACTION",
        //   key: "action",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
      ],
      items: [],
    };
  },
  props: ["tanggal", "station", "query", "tabIndex"],
  computed: {
    rows() {
      return this.items.length;
    },
    items_sorted() {
      return this.items.sort((a, b) => {
        return Date.parse(b.date_sort) - Date.parse(a.date_sort);
      });
    },
  },
  mounted() {
    // this.getAllQCData();
    // this.getAllQCData(null);
  },
  methods: {
    toTitleCase(str) {
      if (str) {
        const arr = str.split("_");
        const result = [];
        for (const word of arr) {
          result.push(word.charAt(0).toUpperCase() + word.slice(1));
        }
        return result.join(" ");
      }
      return "";
    },

    getAllQCData(addquery) {
      // let config = {
      //   mode: "cors",
      //   withCredentials: false,
      //   params: {
      //     type_name: "QcNotification",
      //     qc_flag: 2,
      //     _metadata: "id,form_type,tanggal_pengamatan,jam_pengamatan,histories,qc_flag,station_id,station_name,observer_id,observer_name,qc_is_read,region_id,path_oinput",
      //     _size: 100,
      //   },
      //   headers: Helper.getHeader(),
      // };
      let config = {
        mode: "cors",
        withCredentials: false,
        params: {
          type_name: "Sinoptik",
          _size: 1000,
        },
        headers: Helper.getHeader(),
      };

      if (addquery) {
        for (var key in addquery) {
          config.params[key] = addquery[key];
        }
      }

      // console.log("config", config);
      const get_url = Helper.getBaseUrl() + "@aggregation_query";

      axios
        .get(get_url, config)
        .then((response) => {
          const { data } = response;
          this.items = [];
          data.forEach((row) => {
            let qc_type = [];
            let atribut = [];
            // const histories = JSON.parse(row.histories);
            // console.log("histories", histories, histories.after);
            let histories =
              row.type_name != "Fdih"
                ? JSON.parse(row.qc_histories)
                : JSON.parse(row[this.getProperty(row)[1]]);
            histories.after?.forEach((item) => {
              if (qc_type && !qc_type.includes(item.type)) {
                qc_type.push(item.type);
              }
              atribut.push(item.atribut);
            });

            let date_sort = null; //datetime + ":00";
            if (row.data_timestamp) {
              date_sort = row.data_timestamp;
            }
            // if (row.tanggal_pengamatan) {
            //   date_sort = row.tanggal_pengamatan.split("T")[0];
            //   if (row.jam_pengamatan) {
            //     date_sort += "T" + row.jam_pengamatan;
            //   }
            // }

            this.items.push({
              id: row.id,
              station_id: row.station_id,
              station_name: row.station_name,
              form_type: row.type_name,
              data_timestamp: row.hasOwnProperty("tgl_pasang")
                ? row.tgl_pasang.split("T")[0]
                : row.data_timestamp?.split("T")[0],
              observation_time: row.hasOwnProperty("tgl_pasang")
                ? row.tgl_pasang.split("T")[0]
                : row.data_timestamp?.split("T")[1],
              parameter: atribut,
              qc_type: qc_type,
              observer_name: row.observer_name,
              date_sort: date_sort,
              path_oinput: row.path,
              histories: histories, //row.histories,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getProperty(row) {
      if (row) {
        if (row.hasOwnProperty("qc_flag_fklim")) {
          return ["qc_flag_fklim", "qc_histories_fklim"];
        } else if (row.hasOwnProperty("qc_flag_iklim_mikro")) {
          return ["qc_flag_iklim_mikro", "qc_histories_iklim_mikro"];
        } else if (row.hasOwnProperty("qc_flag_agm1a")) {
          return ["qc_flag_iklim_mikro", "qc_histories_agm1a"];
        } else if (row.hasOwnProperty("qc_flag_agm1b")) {
          return ["qc_flag_agm1b", "qc_histories_agm1b"];
        } else if (row.hasOwnProperty("qc_flag_op_penguapan")) {
          return ["qc_flag_op_penguapan", "qc_histories_op_penguapan"];
        } else if (row.hasOwnProperty("qc_flag_psychrometer_assman")) {
          return [
            "qc_flag_psychrometer_assman",
            "qc_histories_psychrometer_assman",
          ];
        } else if (row.hasOwnProperty("qc_flag_suhu_tanah")) {
          return ["qc_flag_suhu_tanah", "qc_histories_suhu_tanah"];
        } else if (row.hasOwnProperty("qc_flag_gunbellani")) {
          return ["qc_flag_gunbellani", "qc_histories_gunbellani"];
        }
      }
      return null;
    },
  },
  watch: {
    query(value) {
      // console.log("query", value);
      if (this.tabIndex == 1) {
        this.getAllQCData(value);
      }
    },
    // tanggal(value) {
    //   this.getAllQCData();
    // },
    // station(value) {
    //   this.getAllQCData();
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
