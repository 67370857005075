<template>
  <div>
    <!-- <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="table"></b-pagination> -->
    <div>
      <b-table
        id="table"
        :per-page="perPage"
        :bordered="true"
        :current-page="currentPage"
        :items="items_sorted"
        :fields="fields"
        striped
        responsive
        hover
      >
        <template #cell(form_type)="row">
          <span class="font-weight-bold text-dark w-50 text-center">
            {{ toTitleCase(row.item.form_type) }}</span
          >
        </template>
        <template #cell(qc_type)="row">
          <span class="font-weight-bold text-dark w-50 text-center">
            {{ toTitleCase(row.item.qc_type.toString()) }}</span
          >
        </template>
        <template #cell(parameter)="row">
          <span class="font-weight-bold text-dark w-50 text-center">
            {{ toTitleCase(row.item.parameter.toString()) }}</span
          >
        </template>
        <template #cell(action)="row">
          <b-button
            variant="primary"
            size="sm"
            @click="confirmationApprovement(row.item)"
          >
            Approve
          </b-button>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <!-- <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="table"></b-pagination> -->
    </div>

    <div>
      <b-modal
        id="modal-preview"
        ref="modal-preview"
        title="Preview Data Suspect"
        button-size="sm"
        size="lg"
        centered
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
      >
        <div>
          <b-row class="mt-1 mb-1">
            <b-col cols="4">
              <span class="font-weight-bold"
                >TANGGAL: {{ preview_tanggal.toUpperCase() }}</span
              >
            </b-col>
            <b-col cols="4">
              <span class="font-weight-bold"
                >FORM: {{ preview_form.toUpperCase() }}</span
              >
            </b-col>
            <b-col v-if="preview_observation_time" cols="4">
              <span class="font-weight-bold"
                >TIME: {{ preview_observation_time.toUpperCase() }}</span
              >
            </b-col>
          </b-row>
        </div>
        <b-table
          id="table-preview"
          :per-page="perPagePreview"
          :current-page="currentPagePreview"
          :items="preview_items"
          :fields="preview_fields"
          striped
          responsive
          hover
          sticky-header
        >
          <template #cell(raw_type)="row">
            <span class="font-weight-bold text-dark w-50 text-center">
              {{ toTitleCase(row.item.raw_type.toUpperCase()) }}</span
            >
          </template>
          <!-- <template #cell(parameter)="row">
            <span class="font-weight-bold text-dark w-50 text-center"> {{ toTitleCase(row.item.parameter.toString()) }}</span>
          </template>
          <template #cell(action)="row">
            <b-button variant="primary" size="sm" @click="approveData(row.item)">
              Approve
            </b-button>
          </template> -->
        </b-table>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-left">Approve data suspect ?</p>
            <b-button
              variant="secondary"
              size="sm"
              class="float-right ml-1"
              @click="cancelApprovement"
            >
              Tidak
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="approveData"
            >
              Ya
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BPagination,
  BCardBody,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import QCHelper from "@/api/qc_helper";
import metadata from "@/api/metadata";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BPagination,
    BCardBody,
    BFormSelect,
    BModal,
  },
  data() {
    return {
      headerBgVariant: "warning",
      headerTextVariant: "dark",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      // footerBgVariant: 'warning',
      // footerTextVariant: 'dark',
      perPagePreview: 100,
      pageOptionsPreview: [5, 10, 15, 20],
      currentPagePreview: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      fields: [
        {
          label: "STASIUN",
          key: "station_name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "DATA TIMESTAMP",
          key: "data_timestamp",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "FORM",
        //   key: "form_type",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "OBSERVATION TIME",
          key: "observation_time",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "QC TYPE",
          key: "qc_type",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "PARAMATER",
          key: "parameter",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "ACTION",
          key: "action",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      items: [],
      preview_fields: [
        // {
        //   label: "FORM",
        //   key: "raw_type",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "PARAMETER",
          key: "atribut",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "NILAI",
          key: "value",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "ROLES",
          key: "message",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      preview_items: [],
      preview_tanggal: "",
      preview_form: "",
      preview_observation_time: null,
      approvement_data: null,
    };
  },

  props: ["tanggal", "station", "query", "tabIndex"],

  computed: {
    rows() {
      return this.items.length;
    },
    items_sorted() {
      return this.items.sort((a, b) => {
        return Date.parse(b.date_sort) - Date.parse(a.date_sort);
      });
    },
  },

  mounted() {
    // this.getAllQCData(null);
  },

  methods: {
    toTitleCase(str) {
      if (str) {
        const arr = str.split("_");
        const result = [];
        for (const word of arr) {
          result.push(word.charAt(0).toUpperCase() + word.slice(1));
        }
        return result.join(" ");
      }
      return "";
    },

    getAllQCData(addquery) {
      // let config = {
      //   mode: "cors",
      //   withCredentials: false,
      //   params: {
      //     type_name: "QcNotification",
      //     qc_flag: 1,
      //     _metadata: "id,form_type,tanggal_pengamatan,jam_pengamatan,histories,qc_flag,station_id,station_name,observer_id,observer_name,qc_is_read,region_id,path_oinput",
      //     _size: 1000,
      //   },
      //   headers: Helper.getHeader(),
      // };
      let config = {
        mode: "cors",
        withCredentials: false,
        params: {
          type_name: "Sinoptik",
          _size: 1000,
        },
        headers: Helper.getHeader(),
      };

      if (addquery) {
        for (var key in addquery) {
          config.params[key] = addquery[key];
        }
      }

      // console.log("config", config);
      const get_url = Helper.getBaseUrl() + "@aggregation_query";

      axios
        .get(get_url, config)
        .then((response) => {
          const { data } = response;
          this.items = [];
          data.forEach((row) => {
            let qc_type = [];
            let atribut = [];
            let histories =
              row.type_name != "Fdih"
                ? JSON.parse(row.qc_histories)
                : JSON.parse(row[this.getProperty(row)[1]]);

            // console.log("histories", histories, histories.after);
            histories.after?.forEach((item) => {
              if (qc_type && !qc_type.includes(item.type)) {
                qc_type.push(item.type);
              }
              atribut.push(item.atribut);
            });

            let date_sort = null; //datetime + ":00";
            if (row.data_timestamp) {
              date_sort = row.data_timestamp; //.split("T")[0];
              // if (row.jam_pengamatan) {
              //   date_sort += "T" + row.jam_pengamatan;
              // }
            }

            this.items.push({
              id: row.id,
              station_id: row.station_id,
              station_name: row.station_name,
              form_type: row.type_name, //row.form_type,
              data_timestamp: row.hasOwnProperty("tgl_pasang")
                ? row.tgl_pasang.split("T")[0]
                : row.data_timestamp?.split("T")[0], // || datetime?.split("T")[0],
              observation_time: row.hasOwnProperty("tgl_angkat")
                ? row.tgl_angkat.split("T")[0]
                : row.data_timestamp?.split("T")[1], //row.jam_pengamatan,
              parameter: atribut,
              qc_type: qc_type,
              observer_name: row.observer_name,
              date_sort: date_sort,
              path_oinput: row.path,
              histories: histories, //row.histories,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getProperty(row) {
      if (row) {
        if (row.hasOwnProperty("qc_flag_fklim")) {
          return ["qc_flag_fklim", "qc_histories_fklim"];
        } else if (row.hasOwnProperty("qc_flag_iklim_mikro")) {
          return ["qc_flag_iklim_mikro", "qc_histories_iklim_mikro"];
        } else if (row.hasOwnProperty("qc_flag_agm1a")) {
          return ["qc_flag_iklim_mikro", "qc_histories_agm1a"];
        } else if (row.hasOwnProperty("qc_flag_agm1b")) {
          return ["qc_flag_agm1b", "qc_histories_agm1b"];
        } else if (row.hasOwnProperty("qc_flag_op_penguapan")) {
          return ["qc_flag_op_penguapan", "qc_histories_op_penguapan"];
        } else if (row.hasOwnProperty("qc_flag_psychrometer_assman")) {
          return [
            "qc_flag_psychrometer_assman",
            "qc_histories_psychrometer_assman",
          ];
        } else if (row.hasOwnProperty("qc_flag_suhu_tanah")) {
          return ["qc_flag_suhu_tanah", "qc_histories_suhu_tanah"];
        } else if (row.hasOwnProperty("qc_flag_gunbellani")) {
          return ["qc_flag_gunbellani", "qc_histories_gunbellani"];
        }
      }
      return null;
    },

    confirmationApprovement(data) {
      this.preview_observation_time = null;
      this.approvement_data = data;
      let t_items = [];
      this.preview_tanggal = data.data_timestamp.split("T")[0];
      this.preview_form = data.form_type;
      this.checkFormatTable(data.form_type);
      this.preview_observation_time = data.data_timestamp?.split("T")[1]; //data.observation_time;

      if (data.type_name != "Fdih") {
        // console.log('data.histories', data.histories, data.histories.length)
        if (data.histories) {
          let histories = data.histories; //JSON.parse(data.histories);
          // histories.before?.forEach((item) => {
          //   this.preview_form = item.raw_type;
          //   t_items.push({ raw_type: item.raw_type, time: item.hasOwnProperty("time") ? item.time : "", atribut: item.atribut, value: item.value, message: item.message });
          // });

          // if (!histories.before) {
          histories.after?.forEach((item) => {
            // console.log('histories.after', histories.after)
            this.preview_form = item.raw_type;
            t_items.push({
              raw_type: item.raw_type,
              time: item.hasOwnProperty("time") ? item.time : "",
              atribut: item.atribut,
              value: item.value,
              message: item.message,
            });
          });
          // }
        }
      } else {
      }
      this.preview_items = t_items;
      this.$refs["modal-preview"].show();
      this.$refs["modal-preview"].show();
    },

    cancelApprovement() {
      this.preview_observation_time = null;
      this.approvement_data = null;
      this.$refs["modal-preview"].hide();
    },

    // approveData() {

    //   new Promise(async (resolve) => {
    //     // this.LOADING_TABLE = true
    //     const{ data } = await Service.getListLogActivity(userID)
    //     let dataLog = data
    //     // console.log('getListLogActivity', data)
    //     for (let log of dataLog) {
    //       let id = log.id
    //       let dates = id.split('_')
    //       log['log_date'] = dates[1].split('T')[0] + " " + dates[1].split('T')[1] +":"+ dates[2] +":"+dates[3]
    //     }

    //     this.DATA_HISTORY = dataLog

    //     this.LOADING_TABLE = false
    //     resolve(data);
    //   });

    // }
    checkFormatTable(type) {
      let isFIDH = false;
      if (
        type == "IKLIM_MIKRO" ||
        type == "FKLIM" ||
        type == "AGM1A" ||
        type == "OP_PENGUAPAN" ||
        type == "GUNBELLANI" ||
        type == "PSYCHROMETER_ASSMAN" ||
        type == "SUHU_TANAH" ||
        type == "AGM1B"
      ) {
        isFIDH = true;
      }
      if (isFIDH) {
        this.preview_fields = [
          {
            label: "PARAMETER",
            key: "atribut",
            tdClass: "text-center",
            thClass: "text-center",
          },
          {
            label: "TIME",
            key: "time",
            tdClass: "text-center",
            thClass: "text-center",
          },
          {
            label: "NILAI",
            key: "value",
            tdClass: "text-center",
            thClass: "text-center",
          },
          {
            label: "ROLES",
            key: "message",
            tdClass: "text-center",
            thClass: "text-center",
          },
        ];
      } else {
        this.preview_fields = [
          {
            label: "PARAMETER",
            key: "atribut",
            tdClass: "text-center",
            thClass: "text-center",
          },
          {
            label: "NILAI",
            key: "value",
            tdClass: "text-center",
            thClass: "text-center",
          },
          {
            label: "ROLES",
            key: "message",
            tdClass: "text-center",
            thClass: "text-center",
          },
        ];
      }
    },

    getPostData(type) {
      // let raw_data = []
      // if (data.histories.length > 0) {
      //   let histories = JSON.parse(data.histories)
      //   if (histories.after)  {
      //     histories.after?.forEach((item) => {
      //       raw_data.push({raw_type: item.raw_type, time: item.hasOwnProperty('time') ? item.time : '', atribut: item.atribut, value: item.value, message: item.message})
      //     })
      //   }
      //   else {
      //     histories.before?.forEach((item) => {
      //       raw_data.push({raw_type: item.raw_type, time: item.hasOwnProperty('time') ? item.time : '', atribut: item.atribut, value: item.value, message: item.message})
      //     })
      //   }
      // }
      // console.log('type', type)
      type = type.toLowerCase();
      if (type == "iklim_mikro") {
        return {
          post_data_form: {
            "BmkgSatu.behaviors.qc.IFdih_IklimMikro_qc": {
              qc_flag_iklim_mikro: 2,
            },
          },
          post_data_qc: {
            qc_flag: 2,
          },
        };
      } else if (type == "fklim") {
        return {
          post_data_form: {
            "BmkgSatu.behaviors.qc.IFdih_Fklim_qc": {
              qc_flag_fklim: 2,
            },
          },
          post_data_qc: {
            qc_flag: 2,
          },
        };
      } else if (type == "op_penguapan") {
        return {
          post_data_form: {
            "BmkgSatu.behaviors.qc.IFdih_OpPenguapan_qc": {
              qc_flag_op_penguapan: 2,
            },
          },
          post_data_qc: {
            qc_flag: 2,
          },
        };
      } else if (type == "gunbellani") {
        return {
          post_data_form: {
            "BmkgSatu.behaviors.qc.IFdih_Gunbellani_qc": {
              qc_flag_gunbellani: 2,
            },
          },
          post_data_qc: {
            qc_flag: 2,
          },
        };
      } else if (type == "agm1a") {
        return {
          post_data_form: {
            "BmkgSatu.behaviors.qc.IFdih_Agm1a_qc": {
              qc_flag_agm1a: 2,
            },
          },
          post_data_qc: {
            qc_flag: 2,
          },
        };
      } else if (type == "psychrometer_assman") {
        return {
          post_data_form: {
            "BmkgSatu.behaviors.qc.IFdih_PsychrometerAssman_qc": {
              qc_flag_psychrometer_assman: 2,
            },
          },
          post_data_qc: {
            qc_flag: 2,
          },
        };
      } else if (type == "agm1b") {
        return {
          post_data_form: {
            "BmkgSatu.behaviors.qc.IFdih_Agm1b_qc": {
              qc_flag_agm1b: 2,
            },
          },
          post_data_qc: {
            qc_flag: 2,
          },
        };
      } else if (type == "suhu_tanah") {
        return {
          post_data_form: {
            "BmkgSatu.behaviors.qc.IFdih_SuhuTanah_qc": {
              qc_flag_suhu_tanah: 2,
            },
          },
          post_data_qc: {
            qc_flag: 2,
          },
        };
      } else {
        return {
          post_data_form: {
            qc_flag: 2,
          },
          post_data_qc: {
            qc_flag: 2,
          },
        };
      }

      return null;
    },

    async approveData() {
      // console.log('approveData', this.approvement_data)
      let get_url = Helper.getBaseUrl() + this.approvement_data.path_oinput;
      let form_type = null;
      if (this.approvement_data) {
        let histories = this.approvement_data.histories;
        if (histories.after && histories.after.length > 0) {
          form_type = histories.after[0].raw_type;
        }
      }

      let post_data_form = {};
      let format_data = this.getPostData(form_type);
      if (format_data) {
        post_data_form = format_data.post_data_form;
      }

      try {
        let dataForm = await QCHelper.approveQC(get_url, post_data_form);

        this.items = this.items.filter(
          (data) => data.id != this.approvement_data.id
        );

        this.$swal({
          title: "Approvement berhasil",
          text: "",
          icon: "success",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$refs["modal-preview"].hide();
          } else if (result.dismiss === "cancel") {
            return;
          }
        });
        // console.log("approveData : ", dataForm, dataNotif);
      } catch (error) {
        console.log("approveData - error : ", error);
      }
    },
  },

  watch: {
    query(value) {
      // console.log("query", value);
      if (this.tabIndex == 0) {
        this.getAllQCData(value);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
