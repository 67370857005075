<template>
  <div>
    <b-card title="Quality Control - Data Suspect">
      <b-row>
        <!-- <b-col sm="2">
          <b-form-group label="Tanggal" label-size="sm" label-for="datepicker-tanggal" size="sm">
            <b-form-datepicker
              id="datepicker-tanggal"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="en"
              size="sm"
              v-model="tanggal"
              :max="max"
              reset-button
            />
          </b-form-group>
        </b-col> -->
        <b-col sm="3">
          <b-form-group>
            <label>Form</label>
            <!-- <v-select v-model="RESOURCE" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="RESOURCE_STORE" :reduce="(item) => item.CODE" class="select-size-sm" /> -->
            <v-select
              v-model="RESOURCE"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="LABEL"
              :options="RESOURCE_STORE"
              class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group>
            <label>Balai</label>
            <v-select
              v-model="REGION"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="LABEL"
              :options="REGION_STORE"
              :reduce="(item) => item.CODE"
              class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Station"
            label-size="sm"
            label-for="select-station"
            size="sm"
          >
            <v-select
              v-model="STATION"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="LABEL"
              :options="STATION_STORE"
              :reduce="(item) => item.CODE"
              class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Date Range" label-for="select-date" size="sm">
            <!-- <a-range-picker
              class="pb-1"
              :value="DATE_RANGE_SELECTED != null && DATE_RANGE_SELECTED != '' ? [moment(DATE_RANGE_SELECTED[0], 'YYYY-MM-DD'), moment(DATE_RANGE_SELECTED[1], 'YYYY-MM-DD')] : DATE_RANGE_SELECTED"
            /> -->
            <a-range-picker
              @change="onChangeRangeDate"
              :value="
                DATE_RANGE_SELECTED != null && DATE_RANGE_SELECTED != ''
                  ? [
                      moment(DATE_RANGE_SELECTED[0], 'YYYY-MM-DD'),
                      moment(DATE_RANGE_SELECTED[1], 'YYYY-MM-DD'),
                    ]
                  : DATE_RANGE_SELECTED
              "
            />
          </b-form-group>
        </b-col>

        <b-col sm="1" style="padding-top: 23px">
          <b-button @click="onFetchData()" class="btn btn-success btn-sm"
            >Fetch</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-tabs v-model="tabIndex">
            <b-tab title="Suspecs" active>
              <Active
                :tanggal="tanggal"
                :station="station"
                :query="QUERY"
                :tabIndex="tabIndex"
              />
            </b-tab>
            <b-tab title="Has Approved" lazy>
              <History
                :tanggal="tanggal"
                :station="station"
                :query="QUERY"
                :tabIndex="tabIndex"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BFormDatepicker,
  BTabs,
  BTab,
} from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import metadata from "@/api/metadata";
import moment from "moment";

import Active from "./components/active";
import History from "./components/history";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormDatepicker,
    BTabs,
    BTab,
    Active,
    History,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);

    return {
      tabIndex: 0,
      max: maxDate,
      tanggal: "",
      REGION: "",
      REGION_STORE: [{ VALUE: "", LABEL: "SEMUA BALAI" }],
      STATION: "",
      STATION_STORE: [{ VALUE: "", LABEL: "SEMUA STASIUN" }],
      RESOURCE: "",
      RESOURCE_STORE: [
        {
          KEY: "AEROSOL",
          LABEL: "AEROSOL",
          CODE: "AEROSOL",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,station_id,station_name,type_name,path,tgl_pasang,tgl_angkat",
        },
        {
          KEY: "AGM1A",
          LABEL: "AGM 1A",
          CODE: "Fdih",
          FLAG_NAME: "qc_flag_agm1a",
          METADATA:
            "id,qc_flag_agm1a,qc_histories_agm1a,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "AGM1B",
          LABEL: "AGM 1B",
          CODE: "Fdih",
          FLAG_NAME: "qc_flag_agm1b",
          METADATA:
            "id,qc_flag_agm1b,qc_histories_agm1b,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "BAROGRAPH",
          LABEL: "BAROGRAPH",
          CODE: "Barograph",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "FKLIM",
          LABEL: "FKLIM 71",
          CODE: "Fdih",
          FLAG_NAME: "qc_flag_fklim",
          METADATA:
            "id,qc_flag_fklim,qc_histories_fklim,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "FORM_HUJAN",
          LABEL: "FORM HUJAN",
          CODE: "FormHujan",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "GUNBELLANI",
          LABEL: "GUNBELANI",
          CODE: "Fdih",
          FLAG_NAME: "qc_flag_gunbellani",
          METADATA:
            "id,qc_flag_gunbellani,qc_histories_gunbellani,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "HUJAN_HELMAN",
          LABEL: "HUJAN HELLMAN",
          CODE: "HujanHellman",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "IKLIM_MIKRO",
          LABEL: "IKLIM MIKRO",
          CODE: "Fdih",
          FLAG_NAME: "qc_flag_iklim_mikro",
          METADATA:
            "id,qc_flag_iklim_mikro,qc_histories_iklim_mikro,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "KAH",
          LABEL: "KUALITAS AIR HUJAN",
          CODE: "KAH",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,station_id,station_name,type_name,path,tgl_pasang,tgl_angkat",
        },
        {
          KEY: "METAR_SPECI",
          LABEL: "METAR SPECI",
          CODE: "MetarSpeci",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "OP_PENGUAPAN",
          LABEL: "OP PENGUAPAN",
          CODE: "Fdih",
          FLAG_NAME: "qc_flag_op_penguapan",
          METADATA:
            "id,qc_flag_op_penguapan,qc_histories_op_penguapan,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "PSYCHROMETER_ASSMAN",
          LABEL: " PSYCHROMETER ASSMAN",
          CODE: "Fdih",
          FLAG_NAME: "qc_flag_psychrometer_assman",
          METADATA:
            "id,qc_flag_psychrometer_assman,qc_histories_psychrometer_assman,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "SINOPTIK",
          LABEL: "SINOPTIK",
          CODE: "Sinoptik",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "SPM",
          LABEL: "SUSPENDED PARTICULATE MATTER",
          CODE: "SPM",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,station_id,station_name,type_name,path,tgl_pasang,tgl_angkat",
        },
        {
          KEY: "SO2NO2",
          LABEL: "SO2NO2",
          CODE: "SO2NO2",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,station_id,station_name,type_name,path,tgl_pasang,tgl_angkat",
        },
        {
          KEY: "SUHU_TANAH",
          LABEL: "SUHU TANAH",
          CODE: "Fdih",
          FLAG_NAME: "qc_flag_suhu_tanah",
          METADATA:
            "id,qc_flag_suhu_tanah,qc_histories_suhu_tanah,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
        {
          KEY: "THERMOHIGOGRAPH",
          LABEL: "THERMOHIGOGRAPH",
          CODE: "Thermohigograph",
          FLAG_NAME: "qc_flag",
          METADATA:
            "id,qc_flag,qc_histories,observer_name,station_id,station_name,data_timestamp,type_name,path",
        },
      ],
      DATE_RANGE_SELECTED: [],
      QUERY: {},
      moment,
    };
  },
  mounted() {
    this.listStasiun();
    this.listRegion();
    this.RESOURCE = "";
  },
  methods: {
    // filterOption(input, option) {
    //   return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    // },
    async listStasiun() {
      this.STATION_STORE = [];
      try {
        const { data } = await metadata.getAllStasiunWithWMO();
        for (var i = 0; i < data.length; i++) {
          const data_station = {
            KEY: data[i].station_id,
            LABEL: data[i].station_name,
            CODE: data[i].station_name,
          };

          this.STATION_STORE.push(data_station);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async listRegion() {
      this.REGION_STORE = [];
      try {
        const { data } = await metadata.getRegionList();
        for (var i = 0; i < data.length; i++) {
          const data_region = {
            KEY: data[i].region_id,
            LABEL: data[i].region_description,
            CODE: data[i].region_id,
          };

          this.REGION_STORE.push(data_region);
        }
        this.station = "";
      } catch (err) {
        console.log(err);
      }
    },

    onChangeRangeDate(date, dateString) {
      // console.log('onChangeRangeDate', dateString)
      if (dateString) {
        if (dateString[0] == "") {
          this.DATE_RANGE_SELECTED = [];
        } else {
          this.DATE_RANGE_SELECTED = dateString;
        }
      }
    },

    //   onFetchData() {
    //     // console.log('onFetchData', this.RESOURCE, this.REGION, this.STATION, this.DATE_RANGE_SELECTED)
    //     let t_query = {};
    //     if (this.RESOURCE) {
    //       t_query["form_type"] = this.RESOURCE;
    //     }

    //     if (this.REGION) {
    //       t_query["region_id"] = this.REGION;
    //     }

    //     if (this.STATION) {
    //       t_query["station_name"] = this.STATION;
    //     }

    //     if (this.DATE_RANGE_SELECTED && Object.keys(this.DATE_RANGE_SELECTED).length > 0) {
    //       t_query["tanggal_pengamatan__gte"] = this.DATE_RANGE_SELECTED[0];
    //       t_query["tanggal_pengamatan__lte"] = this.DATE_RANGE_SELECTED[1];
    //     }

    //     this.QUERY = t_query;
    //   },
    // },

    onFetchData() {
      // console.log('onFetchData', this.tabIndex)
      let t_query = {};
      if (this.RESOURCE) {
        t_query["type_name"] = this.RESOURCE.CODE;
        if (this.tabIndex == 0) {
          t_query[this.RESOURCE.FLAG_NAME] = 1;
        } else {
          t_query[this.RESOURCE.FLAG_NAME] = 2;
        }
        t_query["_metadata"] = this.RESOURCE.METADATA;
      }

      if (this.REGION) {
        // t_query["region_id"] = this.REGION;
      }

      if (this.STATION) {
        t_query["station_name"] = this.STATION;
      }

      if (
        this.DATE_RANGE_SELECTED &&
        Object.keys(this.DATE_RANGE_SELECTED).length > 0
      ) {
        if (
          this.RESOURCE.CODE == "KAH" ||
          this.RESOURCE.CODE == "SPM" ||
          this.RESOURCE.CODE == "SO2NO2" ||
          this.RESOURCE.CODE == "AEROSOL"
        ) {
          t_query["tgl_angkat__gte"] = this.DATE_RANGE_SELECTED[0];
          t_query["tgl_angkat__lte"] = this.DATE_RANGE_SELECTED[1];
        } else {
          t_query["data_timestamp__gte"] = this.DATE_RANGE_SELECTED[0];
          t_query["data_timestamp__lte"] = this.DATE_RANGE_SELECTED[1];
        }
      }

      this.QUERY = t_query;
    },
  },

  watch: {
    async REGION(value) {
      let id = value;
      this.STATION_STORE = [];
      try {
        this.STATION = "";
        const { data } = await metadata.getStasiunByRegion(id);
        for (var i = 0; i < data.length; i++) {
          const data_station = {
            KEY: data[i].station_id,
            LABEL: data[i].station_name,
            CODE: data[i].station_name,
          };

          this.STATION_STORE.push(data_station);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
